<template>
    <div class="h-100" style="background-color: #606266">
        <div class="ctr h-100 overflow-hidden">
            <live-application>
            </live-application>
        </div>
    </div>
</template>

<script>
import LiveApplication from "@/views/mobile/application.vue"

export default {
    name: "application",
    components: {LiveApplication}
}
</script>

<style scoped>

</style>
